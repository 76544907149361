<template>
  <!-- 代理商列表页面 -->
  <div class="container">
    <a-row>
      <a-col :span="24" class="right">
        <div class="right-content">
          <!-- 筛选 -->
          <div class="table-page-search-wrapper">
            <a-form layout="inline">
              <a-row :gutter="40">
                <!-- 代理商姓名 -->
                <a-col :md="6" :sm="24">
                  <a-form-item label="关键词">
                    <a-input v-model="queryParam.keyword" allow-clear placeholder="关键词搜索" />
                  </a-form-item>
                </a-col>
                <!-- 状态 -->
                <a-col :md="6" :sm="24">
                  <a-form-item label="状态">
                    <a-select v-model="queryParam.delFlag" allow-clear placeholder="请选择">
                      <a-select-option :value="1">正常</a-select-option>
                      <a-select-option :value="3">禁用</a-select-option>
                    </a-select>
                  </a-form-item>
                </a-col>
                <!-- 代理区域 -->
                <a-col :md="12" :sm="24">
                  <a-form-item label="代理区域">
                    <!-- 省选择框 -->
                    <a-select
                      placeholder="请选择"
                      style="width: 160px"
                      @focus="focusProv"
                      @change="changeProv"
                      v-model="queryParam.provinceId"
                    >
                      <a-select-option :value="item.districtId" v-for="(item, index) in provinceList" :key="index">
                        {{ item.districtName }}
                      </a-select-option>
                    </a-select>
                    <!-- 市选择框 -->
                    <a-select placeholder="请选择" style="width: 160px" v-model="queryParam.cityId">
                      <a-select-option :value="item.districtId" v-for="(item, index) in cityList" :key="index">
                        {{ item.districtName }}
                      </a-select-option>
                    </a-select>
                  </a-form-item>
                </a-col>
                <!-- 按钮 -->
                <a-col :md="6" :sm="24">
                  <a-button type="primary" @click="initTableData">搜索</a-button>
                  <a-button style="margin-left: 8px" @click="() => (queryParam = {})">重置</a-button>
                </a-col>
              </a-row>
            </a-form>
          </div>
          <!-- 表格 -->
          <a-table :columns="columns" :data-source="data" :scroll="{ x: 1300 }" :loading="loading" :pagination="false">
            <!-- 视频列表状态插槽 -->
            <span slot="delFlag" slot-scope="text, record" @click="clickSwitch(record)">
              <a-switch
                checked-children="启用"
                un-checked-children="禁用"
                v-model="record.checked"
                @change="changeSwitch"
              />
            </span>
            <span slot="categoryNames" slot-scope="text">
              {{ text ? (text.split('').length > 20 ? text.substring(0, 20) + '...' : text) : '' }}
            </span>
            <!-- 操作插槽 -->
            <span slot="action" slot-scope="text, record">
              <a @click="$refs.detailForm.detail(record)">详情</a>
              <a style="margin-left: 10px" @click="$refs.storeList.init(record.id)">商家列表</a>
              <!-- <a-divider type="vertical" />
              <a-popconfirm placement="topRight" title="确认删除？" @confirm="() => sysItemDelete(record)">
                <a>删除</a>
              </a-popconfirm> -->
            </span>
          </a-table>
          <!-- 分页组件 -->
          <div style="margin-top: 20px; display: flex; flex-direction: row-reverse">
            <a-pagination
              :pageSize="page.pageSize"
              :current="page.pageNo"
              show-size-changer
              :page-size-options="['10', '20', '50', '100']"
              show-quick-jumper
              :total="total"
              :show-total="(total) => `共 ${total} 条`"
              @change="pageChange"
              @showSizeChange="sizeChange"
            ></a-pagination>
          </div>
        </div>
      </a-col>
    </a-row>
    <!-- 引入子组件 -->
    <detail-form ref="detailForm"></detail-form>
    <store-list ref="storeList"></store-list>
  </div>
</template>

<script>
import { agentPageList, agentDetail, agentDelete } from '@/api/modular/mallLiving/agent'
import StoreList from './store-list'
import detailForm from './detailForm.vue'
const columns = [
  {
    title: '序号',
    width: '100px',
    align: 'center',
    customRender: (text, record, index) => `${index + 1}`,
  },
  {
    title: '代理商姓名',
    width: '100px',
    dataIndex: 'name',
    key: 'name',
    align: 'center',
  },
  {
    title: 'ID号',
    width: '130px',
    dataIndex: 'idNo',
    align: 'center',
  },
  {
    title: '手机号',
    width: '150px',
    dataIndex: 'mobile',
    align: 'center',
  },
  {
    title: '身份证号',
    align: 'center',
    width: '150px',
    dataIndex: 'cardNumber',
    key: 'cardNumber',
  },
  {
    title: '公司名称',
    dataIndex: 'companyName',
    width: '130px',
    key: 'companyName',
    align: 'center',
  },
  {
    title: '邀请码',
    dataIndex: 'agentCode',
    width: '100px',
    key: 'agentCode',
    align: 'center',
  },
  {
    title: '社会信用代码',
    dataIndex: 'socialCreditCode',
    width: '150px',
    key: 'socialCreditCode',
    align: 'center',
  },
  {
    title: '法人',
    dataIndex: 'legalRepresentativeName',
    width: '100px',
    key: 'legalRepresentativeName',
    align: 'center',
  },
  {
    title: '代理区域',
    dataIndex: 'cityName',
    width: '100px',
    key: 'cityName',
    align: 'center',
  },
  {
    title: '代理类目',
    dataIndex: 'categoryNames',
    width: '100px',
    key: 'cityName',
    align: 'center',
    scopedSlots: { customRender: 'categoryNames' },
  },
  {
    title: '邮箱',
    dataIndex: 'email',
    width: '130px',
    key: 'email',
    align: 'center',
  },
  {
    title: '状态',
    dataIndex: 'delFlag',
    width: '100px',
    key: 'delFlag',
    align: 'center',
    scopedSlots: { customRender: 'delFlag' },
  },
  {
    title: '创建时间',
    dataIndex: 'createTime',
    width: '150px',
    key: 'createTime',
    align: 'center',
  },
  {
    title: '操作',
    width: '130px',
    align: 'center',
    fixed: 'right',
    dataIndex: 'action',
    key: 'action',
    scopedSlots: { customRender: 'action' },
  },
]

export default {
  data() {
    return {
      columns,
      data: [],
      page: {
        pageNo: 2,
        pageSize: 10,
      },
      total: 0,
      recordItem: null,
      loading: false,
      queryParam: {},
      videoTypeList: [], //视频分类数据
      provinceList: [], //省份列表
      cityList: [], //市区列表
    }
  },
  components: {
    detailForm,
    StoreList,
  },
  mounted() {
    this.initTableData()
  },
  methods: {
    //切换页面
    pageChange(pageNo, pageSize) {
      this.page.pageNo = pageNo
      this.page.pageSize = pageSize
      this.getList()
    },
    sizeChange(pageNo, pageSize) {
      this.page.pageNo = pageNo
      this.page.pageSize = pageSize
      this.getList()
    },
    initTableData() {
      this.page.pageNo = 1
      this.getList()
    },
    focusProv() {
      this.findProv()
    },
    //查询省份信息
    findProv() {
      this.axios.post('/sysDistrict/allProvince').then((res) => {
        if (res.success) {
          this.provinceList = res.data
        }
      })
    },
    changeProv() {
      this.cityList = ''
      delete this.queryParam.cityId
      console.log('22', this.cityList, '33', this.queryParam.cityId)
      this.axios.post('/sysDistrict/findCity', null, { params: { id: this.queryParam.provinceId } }).then((res) => {
        if (res.success) {
          this.cityList = res.data
        }
      })
    },
    getList() {
      this.loading = true
      var params = {
        page: this.page,
        queryParam: this.queryParam,
      }
      agentPageList(params)
        .then((res) => {
          if (res.success) {
            this.data = res.data.rows
            this.data.forEach((item) => {
              item.checked = item.delFlag == 1
            })
            this.total = res.data.totalRows
            this.loading = false
          }
        })
        .finally((res) => {
          setTimeout(() => {
            this.loading = false
          }, 5000)
        })
    },
    // 删除
    sysItemDelete(record) {
      this.recordItem = record
      this.disableCommodityCate(2) //2:删除
    },
    clickSwitch(record) {
      this.recordItem = record
    },
    changeSwitch(e) {
      let that = this
      let delFlag = e ? 1 : 3
      setTimeout(function () {
        that.disableCommodityCate(delFlag)
      }, 300)
    },
    async disableCommodityCate(delFlag) {
      let params = {
        delFlag: delFlag,
        id: this.recordItem.id,
      }
      let data = await agentDelete(params)
      var text = delFlag == 1 ? '启用' : delFlag == 2 ? '删除' : '禁用'
      if (data.code == 200) {
        this.$message.success(this.recordItem.name + ' 已' + text)
        this.getList()
      }
    },
  },
}
</script>

<style lang="less" scoped>
.container {
  min-width: 1000px;
}
.title {
  color: rgba(16, 16, 16, 100);
  font-size: 14px;
  font-weight: bold;
}
.right {
  border-radius: 2px;
  background-color: rgba(255, 255, 255, 100);
}
.right-content {
  box-sizing: border-box;
  padding: 10px;
}
.icon {
  font-size: 20px;
  margin: 0 5px;
}
img {
  width: 20px;
  height: 20px;
}
</style>
